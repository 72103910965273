.digital-scale {
  padding: 20px 10px 40px;
}
.quality-border {
  border-right: 1px solid #dee2e6 !important;
}
.test-graph-width {
  width:100%
}
.image-container {
  position: relative;
  box-sizing: border-box;
  box-shadow: 10px 5px 7px lightgrey;
  background-color: lightgray;
  width: 100% !important;
}