.navbar {
  position: relative;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0px !important;
  padding-top: 0px !important;
  padding-bottom:0px !important;
  width: 100%;
  align-items: inherit;
  height:50px;
}

.topbar {
  left: 0;
    position: sticky;
    right: 0;
    top: 0px;
    z-index: 999;
    background-color: #f3f3f4;
}

.everest-title {
  position:relative
}
.logout-link {
  cursor: pointer;
  margin-right:15px;
  color: #212529;
}