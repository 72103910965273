.card-body {
  padding: 1rem 1rem 0rem 1rem !important;
}
.card {
  transition: transform .5s, box-shadow .8s;
}
.card:hover {
  transform: scale(1.02) perspective(0px);
  box-shadow: 10px 5px 7px lightgrey;

}
.accordion-header {
  margin-bottom: 0;
  margin-top: 0 !important;
}

.card-header {
  font-size: larger;
  font-weight:bold;
  color: #212529
}