
  .header {
    background-color: #ffffff;
    border-top: 0;
    padding: 0 10px 20px 10px;
    border-bottom: 1px solid #e7eaec;
  }

  h2 {
    margin-top: 20px !important;
    color: #212529
    /* margin-bottom: 10px !important;
    font-size: 24px !important;
    font-weight: 300 !important; */
  }