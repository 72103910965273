.App {
  /* text-align: center; */
  /* background-image: url('./wallpaper-1.jpeg'); */
}
body {
  background: #ffffff;
  margin: 0;
  overflow-x: hidden !important;
  font-size: 0.95rem;
  /* font-family: "open sans", "Helvetica Neue", Helvetica, Arial, sans-serif; */
  font-family: canada-type-gibson, system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  width: 100% 
  
}

html {
  overflow-x: hidden;
  position: relative;
  min-height: 100%;
  background: #ffffff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* font-size: calc(10px + 2vmin); */
  /* color: white; */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
