.navbar-default {
  z-index: 2001;
  width: 220px;
}
.nav-profile {
  padding: 33px 25px;
}
.rounded-circle {
  border-radius: 50%;
}
.float-right {
  float: right
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.mike {
  width: 62px;
  height:62px;
  border: 1px solid #333;
}

.nav-link {
  display: block;
}


.nav-tabs {
  /* border-bottom: 1px solid $nav-tabs-border-color; */

}


.nav-pills {

}

.nav-fill {

}

.nav-justified {

}




.tab-content {

}
