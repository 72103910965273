#wrapper{
height: 100vh;
width: 100%;
overflow: hidden;
display: flex;
}
#page-wrapper {
  padding: 0 12px;
  min-height: 100vh;
  background-color: #f3f3f4;
    position: relative !important;
    overflow-y: scroll;
    flex-shrink: 1;
    width: calc(100% - 270px);
}